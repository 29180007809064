// core version + navigation, pagination, thumbs modules:
import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs]);
$( window ).on("load", function() {
    var testimonials_face_slider = new Swiper('.testimonialsFaceSlider', {
        loop: true,
        speed: 1400,
        clickable: false,
        slidesPerView: 3,
        spaceBetween: 16,
        centerMode: true,
        centeredSlides: true,
        allowTouchMove: false,
        on: {
            init: function () {

            },
        },
    });
    var testimonials_slider = new Swiper('.testimonialsSlider', {
        loop: true,
        speed: 1400,
        clickable: true,
        slidesPerView: 1,
        spaceBetween: 16,
        centerMode: true,
        centeredSlides: true,
        pagination: {
            el: '.testimonials__slider--pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.testimonials__slider--navNext',
            prevEl: '.testimonials__slider--navPrev',
        },
    });

    testimonials_face_slider.on("slideChange", () => {
        testimonials_slider.slideTo(testimonials_face_slider.activeIndex - 2);
    });
    testimonials_slider.on("slideChange", () => {
        testimonials_face_slider.slideTo(testimonials_slider.activeIndex + 2);
    });
});